import { whr_blue, whr_orange } from "../assets/colours"

export const featured_image = `

  .gatsby-image-wrapper{
    &:after{
      content:'';
      display:block;
      position:absolute;
      top:0;
      left:0;
      height:100%;
      width:100%;
      background:rgba(0,0,0,0.15);
    }
  }

  .slick-slider, .slick-list, .slick-track, .slick-slide, .slick-slide > div, .slick-slide .gatsby-image-wrapper {
    height:100%;
  }

  @media (min-width:0px){
    width:100%;
    height:100vh;
  }
  @media (min-width:992px){
    width:calc(50% + 8px);
    position:fixed;
    height:100%;
    top:0;
    right:0;
  }

  img{
    height:100%;
    width:100%;
    object-fit:cover;
  }

`

export const content = `

  h1, h2, h3, h4, h5, h6{
  font-family: "Bebas Neue";
  letter-spacing:1px;
  }

  p, ul, li{
    font-family:"Karla", sans-serif;
  }


  a{
    color:${whr_orange};
  }

    height:100%;

    @media (min-width:0px){
      width:100%;
    }
    @media (min-width:992px){
      width:calc(50% + 2px);
    }

`

export const content_inner = `

    @media (min-width:0px){
      padding:24px;
    }
    @media (min-width:992px){
      padding:48px;
    }
    
    height:100%;

    h1{
    font-size:32px;
    margin-bottom:24px;
    color:${whr_blue};
    }

    p{
      margin-bottom:1.5rem;
    }

    section{
      margin-bottom:48px;
      scroll-padding-top:100px;

      .image-link{
        display:block;
      }

      img{
        max-width:100%;
        height:auto;
      }

      img.brochure{
        -webkit-box-shadow: 5px 5px 5px 0px rgba(207,207,207,1);
        -moz-box-shadow: 5px 5px 5px 0px rgba(207,207,207,1);
        box-shadow: 5px 5px 5px 0px rgba(207,207,207,1);
      }
    }
`
