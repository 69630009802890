import React from "react"
import styled from "styled-components"
import { whr_orange } from "../assets/colours"

const QuickMenu = ({ jump_text, menu_items }) => {
  const menu_links = menu_items.map((menu_item, index) => {
    return (
      <li key={index}>
        <a href={"#" + menu_item.id}>{menu_item.title}</a>
      </li>
    )
  })

  return (
    <Wrapper>
      <h2>{jump_text}</h2>
      <ul>{menu_links}</ul>
    </Wrapper>
  )
}

const Wrapper = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: ${whr_orange};

  @media (min-width: 0px) {
    padding: 24px;
  }
  @media (min-width: 992px) {
    padding: 48px;
  }

  * {
    color: white !important;
  }

  h2 {
    font-size: 2rem;
    margin: 0;
  }

  ul {
    @media (max-width: 767px) {
      width: 100%;
      margin-top: 24px;
    }
    padding-left: 0;
    margin: 0;
    list-style-type: none;

    li {
      &:before {
        display: none;
      }

      margin-bottom: 12px;

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        font-weight: bold;

        font-family: "Karla", sans-serif;
        font-weight: bold;

        &:before {
          display: inline-block;
          content: "+";
          margin-right: 6px;
        }
      }
    }
  }
`

export default QuickMenu
