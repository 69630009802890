import React from "react"
import styled from "styled-components"
import { whr_blue } from "../assets/colours"

const PageHeader = ({ title }) => {
  return (
    <Header>
      <h1>{title}</h1>
    </Header>
  )
}

const Header = styled.header`
  background: ${whr_blue};
  @media (min-width: 0px) {
    padding: 24px;
  }
  @media (min-width: 992px) {
    padding: 24px 48px;
  }
  h1 {
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 1px;
    font-size: 2rem;
  }

  * {
    color: white;
  }
`

export default PageHeader
