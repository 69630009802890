import React, { useState } from "react"
import { content, content_inner, featured_image } from "../assets/global"

import Image from "gatsby-image"
import Layout from "../components/layout"
import PageHeader from "../components/page_header"
import QuickMenu from "../components/quick_menu"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import sanitizeHtml from "sanitize-html"
import styled from "styled-components"
import { whr_orange } from "../assets/colours"

const AboutPage = ({ data, location }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  function toggleMenu() {
    setMenuOpen(!menuOpen)
  }

  const featuredImage = {
    fluid: data.wpPage.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: data.wpPage.featuredImage?.node?.alt || ``,
  }

  const menu_links = [
    {
      title: "Our Vision through 2025",
      id: "our-vision",
    },
    {
      title: "Our Background",
      id: "our-background",
    },
    {
      title: "Mental Health First Aid",
      id: "mhfa",
    },
  ]

  return (
    <Layout
      title={data.wpPage.seo.title}
      menuOpen={menuOpen}
      toggleMenu={toggleMenu}
      fullWidthMenu={false}
    >
      <Seo
        description={data.wpPage.seo.metaDesc}
        title={data.wpPage.seo.title}
      />
      <FeaturedImage>
        <Image
          fluid={featuredImage.fluid}
          alt={featuredImage.alt}
          style={{ height: "100%" }}
        />
      </FeaturedImage>
      <Content>
        <PageHeader title={data.wpPage.title}></PageHeader>
        <QuickMenu jump_text="Jump To" menu_items={menu_links}></QuickMenu>
        <ContentInner>
          {data.wpPage.pageTemplateAbout.visionShow === true && (
            <section id="our-vision">
              <h1>Our Vision</h1>

              {data.wpPage.pageTemplateAbout.visionItems.map(
                (vision_item, index) => {
                  return (
                    <Vision key={index}>
                      <div className="number">{index + 1}</div>
                      <div
                        className="description"
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHtml(vision_item.description),
                        }}
                      ></div>
                    </Vision>
                  )
                }
              )}
            </section>
          )}
          {data.wpPage.pageTemplateAbout.aboutShow === true && (
            <section id="our-background">
              <h1>Our Background</h1>

              <article
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(data.wpPage.pageTemplateAbout.aboutUs, {
                    allowedTags: [
                      "a",
                      "h1",
                      "h2",
                      "h3",
                      "h4",
                      "h5",
                      "h6",
                      "p",
                      "strong",
                      "img",
                      "ul",
                      "li",
                      "div",
                    ],
                    allowedAttributes: {
                      a: ["style", "href", "target", "rel"],
                      img: ["src", "alt", "class"],
                      ul: ["style"],
                      div: ["style"],
                    },
                  }),
                }}
              ></article>
            </section>
          )}
          {data.wpPage.pageTemplateAbout.mhfaShow === true && (
            <section id="mhfa">
              <h1>Mental Health First Aid</h1>

              <div className="mhfa-wrapper">
                <Image
                  fluid={
                    data.wpPage.pageTemplateAbout.mhfaLogo.localFile
                      .childImageSharp.fluid
                  }
                  alt="The Mental Health First Aid Gold badge"
                  style={{ height: "100%" }}
                />

                <article
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(
                      data.wpPage.pageTemplateAbout.mhfaIntro
                    ),
                  }}
                ></article>
              </div>
            </section>
          )}
        </ContentInner>
      </Content>
    </Layout>
  )
}

const Content = styled.div`
  ${content};
`

const Vision = styled.article`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
  padding-bottom: 24px;
  padding-top: 24px;
  border-bottom: 2px solid ${whr_orange};

  .description {
    @media (min-width: 0px) {
      width: 100%;
    }
    @media (min-width: 768px) {
      flex: 1;
    }
  }

  .number {
    margin-right: 48px;
    font-size: 96px;
    font-weight: bold;
    color: ${whr_orange};
    font-family: "Bebas Neue", sans-serif;
    line-height: 1;

    @media (max-width: 767px) {
      width: 100%;
      text-align: center;
      margin-right: 0;
      margin-bottom: 18px;
    }
  }
`

const FeaturedImage = styled.div`
  ${featured_image};
`

const ContentInner = styled.div`
  ${content_inner};

  .mhfa-wrapper {
    display: flex;
    flex-wrap: wrap;

    .gatsby-image-wrapper {
      height: 200px;
      width: 200px;

      @media (max-width: 768px) {
        margin: 0 auto;
      }

      margin-bottom: 36px;
    }
    article {
      flex-grow: 1;
    }
  }
`

export const pageQuery = graphql`
  {
    wpPage(slug: { eq: "about" }) {
      seo {
        metaDesc
        title
      }
      id
      title
      slug
      pageTemplateAbout {
        aboutShow
        aboutUs
        visionShow
        visionItems {
          description
          icon {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1800, quality: 100) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
        mhfaIntro
        mhfaShow
        mhfaLogo {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1800, quality: 100) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1800, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export default AboutPage
